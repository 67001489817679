/*------------------------------------------------------------------------------Variables */
@import 'variables';
/*------------------------------------------------------------------------------Animaciones */
@import 'animate';
/*------------------------------------------------------------------------------Normalizador */
@import 'normalize';
/*------------------------------------------------------------------------------Mixins */
@import 'mixins';
/*------------------------------------------------------------------------------Tipografía */
@import 'fonts';

/*------------------------------------------------------------------------------Grillas */
.row {
  width: 100%; margin-left: auto; margin-right: auto;
}

//Generador de grilla
@include grid-generator(12, 20, 1450, grid);
/*------------------------------------------------------------------------------Auxiliares */
.is-loading-page{
  opacity: 0;
  visibility: none;
  transition: opacity 300ms ease;
  &.active{
    opacity: 1;
    visibility: visible;
  }
}

.to-the-left {float: left;}
.to-the-right {float: right;}

.align-left {text-align: left}

.align-center {text-align: center}
.align-right {text-align: right}

.relative-position {position: relative;}

.inline-list {
  display: table;
  width: 100%;
  li {
    display: table-cell;
    vertical-align: middle;
  }
  &.floated {
    display: block;
    @extend .clearfix;
    li {
      display: block;
      float: left;
    }
  }
}

.vertical-list{
  display: block;
  li{
    display: block;
    a{
      display: block;
      padding: 10px;
    }
  }
}

//Clases para realizar animaciones con delay
@for $i from 0 through 11 {
  .delay-animation.delay-#{$i} {
    animation-delay: ($i * 450ms);
  }
}

@for $i from 1 through 5{
  .m-r-#{$i * 10}{
    margin-right: ($i * 10px);
  }
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
}

.button-reset {
  @include appearance(none);
  background: transparent;
  border-radius: 0px;
  border: 0px;
  outline: 0px;
  padding: 0px;
}

.elastic-img {
  width: 100%;
  height: auto;
}

.elastic-img-limit {
  max-width: 100%;
  height: auto;
}

.centered-img {
  display: block;
  margin: 0 auto;
}

.clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.rounded-img {
  border-radius: 100%;
  border: 1px solid $border-color;
}

.clear-margin {
  margin: 0;
}

.margin-bottom-wrapp {
  margin-bottom: 20px;
}

.container{
  padding-left: 20px;
  padding-right: 20px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.ohidden{
  overflow: hidden;
}

.hide{
  display: none;
}

.box-wrapp{
  padding: $padding-base
}

.bordered{
  border:1px solid $border-color;
  &.top{
    border-bottom: 0 none;
    border-left: 0 none;
    border-right: 0 none;
  }
}

.disabled{
  opacity: 0.3;
  pointer-events: none;
}

.disable-interaction{
  pointer-events: none;
}

.inline-left{
  margin-right: 10px;
}

.inline-element{
  display: inline-block;
}

.block-element{
  display: block;
}

.wrapp-30-0{
  padding: 30px 0;
}

.bordered-bottom{
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom:1px solid $border-color;
}
/*------------------------------------------------------------------------------Generales */
html {
  font-size: #{$font-size-base}px;
}

body {
  font-family: $font-family-base;
  line-height: $line-height-base;
  background-color: $body-bg;
  font-size: #{$font-size-base}px;
  -webkit-backface-visibility: hidden;
  color: $text-color;
  &.preload{
    overflow: hidden;
  }
}

button {
  font-family: $font-family-base;
}

a{
  color: $link-color;
  font-weight: bold;
  text-decoration: none;
  outline: 0 none;
  transition: all 350ms ease;
  &:hover {
    color: darken($link-color, 15%);
    outline: 0 none;
  }
  &:focus {
    outline: 0 none;
  }
}

ul {
  margin-left: 0;
}

p {
  font-size: 1rem;
  margin: 0;
  line-height: $line-height-base;
  margin-bottom: $margin;
  &:last-child {
    margin-bottom: 0;
  }
}

/*------------------------------------------------------------------------------Fondos */


/*------------------------------------------------------------------------------Headlines */

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  color: $titles;
  line-height: 120%;
  margin-bottom: 10px;
}

h1 {font-size: $h1-size}
h2 {font-size: $h2-size}
h3 {font-size: $h3-size}
h4 {font-size: $h4-size;}
h5 {font-size: $h5-size}
h6 {font-size: $h6-size}

.rate-title{
  font-size: #{(18 / $font-size-base)}rem;
}
/*------------------------------------------------------------------------------Buttons */
.btn {
  @extend .button-reset;
  font-family: $font-family-base;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  padding: 10px;
  width: auto;
  height: auto;
  line-height: 120%;
  transition: background 300ms ease;
  color: #FFFFFF;
  transition: all 350ms ease;
  background-color: $primary;
  border-radius: 10px;
  &:hover{
    background-color: lighten($primary, 5%);
    box-shadow: 0px 3px 6px rgba(0,0,0,0.3);
  }
  &.btn-rounded{
    border-radius: 100%;
  }
  &.btn-close{
    width: 32px;
    height: 32px;
    padding: 0;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  &.btn-big{
    font-size: #{(16 / $font-size-base)}rem;
    padding: 10px 20px;
    margin: 5px;
  }
  &.btn-width-150{
    width: 150px
  }
  &.btn-transparent{
    background-color: transparent;
    border: 0 none;
  }
  &.btn-alternative{
    background-color: transparent;
    border: 0 none;
    color: $primary;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    text-align: left;
    &:hover{
      box-shadow: none;
    }
    &:focus{
      &:before{
        background-color: #CCC;
      }
    }
    &:before{
      content: "";
      width: 32px;
      height: 32px;
      border: 1px solid $primary;
      border-radius: 100%;
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
    }
    &.bad-response{
      background-color: transparent;
      color:#c7254e;
      &:before{
        background-color: #c7254e;
        border: 1px solid #c7254e;
        text-align: center;
        line-height: 180%;
      }
    }
    &.good-response{
      background-color: transparent;
      color: #9DDA57;
      &:before{
        background-color: #9DDA57;
        border: 1px solid #9DDA57;
        text-align: center;
        line-height: 180%;
      }
    }
  }
  &.bad-response{
    background-color: #c7254e;
    &:before{
      content: '\f00d';
      font: normal normal normal 14px/1 FontAwesome;
      color: #FFF;
      margin-right: 5px;
      font-size: 18px;
    }
  }
  &.good-response{
    background-color: #9DDA57;
    &:before{
      content: '\f00c';
      font: normal normal normal 14px/1 FontAwesome;
      color: #FFF;
      margin-right: 5px;
      font-size: 18px;
    }
  }
  &.btn-light{
    background-color: transparent;
    color: $primary;
    border: 1px solid $primary;
    border-radius: 100%;
    height: 32px;
    width: 32px;
    padding: 0;
    box-shadow: none;
    &.active{
      background-color: $primary;
      color: #FFF;
    }
  }
  &.btn-header{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-shadow: -3px 0px 6px rgba(0,0,0,0.3);
    background-color: #35abd1;
    border-radius: 0;
    border: 0 none;
    color: #FFF;
    padding: 10px;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
    pointer-events: none;
    z-index: 2;
    &.active{
      opacity: 1;
      visibility: visible;
      transform: translate3d(0,0,0);
      pointer-events: auto;
    }
    &:hover{
      background-color: darken(#35abd1, 15%);
    }
  }
}

.stars-holder{
  overflow: hidden;
  display: inline-block;
}

.star-btn{
  border: 0 none;
  background-color: transparent;
  font-size: #{(30 / $font-size-base)}rem;
  float: right;
  i{
    color: $border-color;
  }
  &:hover, &.active{
    i{
      color: #ffa900;
    }
    ~ .star-btn{
      i{
        color: #ffa900;
      }
    }
  }
  &:focus{
    outline: 0 none;
  }
}

/*------------------------------------------------------------------------------Forms */

label{
  display: block;
  margin-bottom: 10px;
  text-align: left;
}

input, select, textarea {
  @include appearance(none);
  display: block;
  width: 100%;
  margin-bottom: 10px;
  background-color: transparent;
  border: 0 none;
  padding: 10px;
  transition: all 350ms ease;
  height: 45px;
  background-color: #FFF;
  font-size: #{(16 / $font-size-base)}rem;
  border: 1px solid #DDD;
  &:hover{
    border-color: $titles;
  }
  &:focus {
    outline: 0 none;
  }
}

input[type="radio"], 
input[type="checkbox"]{
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid $border-color;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  &:checked{
    background-color: $primary;
  }
  &+ span{
    font-weight: bold;
    margin-right: 20px;
    display: inline-block;
    &.label-like{
      margin-right: 0;
      width: calc(100% - 32px);
      vertical-align: top;
      font-weight: normal;
    }
  }
}

input[type="checkbox"]{
  border-radius: 0;
}

.small-input{
  height: 30px;
  padding: 5px 30px 5px 5px;
}

.invalid-input{
  border: 1px solid #c74325 !important;
}

textarea{
  min-height: 350px;
}

select{
  padding-right: 30px;
  position: relative;
  border-radius: 0;
  &:after{
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.campaign-select-holder{
 @include respond-to('small'){
   float: none;
   padding: 10px 0;
   display: block;
 } 
}

.select-holder{
  position: relative;
  display: inline-block;
  &:after{
    content: "\f0dc";
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 20px;
    color: $primary;
    text-align:center;
    line-height: 320%;
  }
  select{
    margin-bottom: 0;
  }
}
/*------------------------------------------------------------------------------Header */
.main-header{
  padding: 10px;
  border-bottom: 1px solid $border-color;
  box-shadow: 0 3px 6px rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: $main-header-bg;
}

.logo-img{
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.main-title{
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  width: calc(100% - 70px);
}

/*------------------------------------------------------------------------------Content */
.input-user-holder{
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid $border-color;
}

.form-wrapp{
  transition: all 300ms ease;
  border-top: 1px solid #999;
  &.inactive{
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, 100%, 0)
  }
}

.input-user{
  width: calc(100% - 45px);
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  height: 50px;
  background-color: #d6d6d6; 
}

.input-user-btn{
  display: inline-block;
  vertical-align: middle;
}

.conversation-holder{
  padding-top: 80px;
  height: calc(100vh - 61px);
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 50px;
  position: relative;
}

.conversation-bubble{
  margin-bottom: 15px;
  &.to{
    text-align: right;
  }
}

.response-img{
  display: inline-block;
  vertical-align: top;
  width: 50px;
  margin-right: 10px;
}

.bubble{
  display: inline-block;
  background-color: $bubble-bg;
  padding: 15px;
  border-radius: 30px;
  margin-top: 3px;
  max-width: calc(100% - 70px);
  color: $bubble-bg-message-text;
  font-size: #{(14 / $font-size-base)}rem;
  a{
    color: $link_color;
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
  .to &{
    background-color: $bubble-bg-message;
    color: $bubble-bg-message-text-send;
    text-align: left;
  }
}

.loader {
	text-align:center;
	width:100px;
  position: absolute;
  bottom: 100%;
  height: 30px;
  margin: 0 auto;
  left: 0;
  right: 0;
	.dot {
		display:inline-block;
		width:12px;
		height:12px;
		border-radius:50%;
		margin-right:3px;
		background:$primary;
		animation: wave 1.3s linear infinite;
		&:nth-child(2) {
			animation-delay: -1.1s;
		}
		&:nth-child(3) {
			animation-delay: -0.9s;
		}
	}
}

.conversation-wrapper{
  margin-bottom: 50px;
}

.block-textarea{
  border: 1px solid $border-color;
  min-height: 100px;
}



/*------------------------------------------------------------------------------Tables */
table {
  width: 100%;
  th,td{
    text-align: left;
    border-bottom: 1px solid $border-color;
    font-size: #{(12 / $font-size-base)}rem;
  }
  th{
    padding: 5px;
  }
  tr:last-child{
    td{
      border-bottom: 0 none;
    }
  }
  td{
    padding: 5px;
    position: relative;
    transition: background 300ms ease;
    text-align: center;
    color: #e7e7e7;
    &.active{
      font-weight: bold;
    }
    &.main-td{
      font-weight: bold;
      font-size: #{(18 / $font-size-base)}rem;
      min-width: 200px;
      white-space: nowrap;
    }
    @include respond-to('small'){
      white-space: nowrap;
    }
  }
}
/*------------------------------------------------------------------------------Contenedores */
.question-holder{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: #FFF;
  padding: 20px;
  text-align: center;
  overflow: hidden;
  overflow-y: scroll;
  .question-holder-wrapp{
    opacity: 0;
    pointer-events: none;
    transition: all 350ms ease;
  }
  &.active{
    .question-holder-wrapp{
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.question-data{
  opacity: 0;
  visibility: hidden;
  position: absolute;
  pointer-events: none;
  transition: opacity 350ms ease;
  &.active{
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    position: static;
  }
}

.question-last-step{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 350ms ease;
  pointer-events: none;
  background-color: #FFF;
  padding: 40px;
  &.active{
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  i{
    font-size: #{(42 / $font-size-base)}rem;
    margin-bottom: 30px;
  }
}

.question-holder-wrapp{
  margin: 0 auto;
  max-width: 600px;
}

.question-title{
  font-size: #{(14 / $font-size-base)}rem;
  margin-bottom: 30px;
}

.button-list-holder{
  text-align: left;
  list-style: none;
  counter-reset: ol-counter;
  text-align: left;
  max-width: 350px;
  margin: 0 auto;
  margin-bottom: 20px;
  li{
    position: relative;
    padding-left: 45px;
    margin-bottom: 20px;
  }

  .button-list{
    color: $primary;
    background-color: transparent;
    border: 0 none;
    text-align: left;
    padding: 0;
    padding-top: 12px;
    font-size: #{(12 / $font-size-base)}rem;
    &:hover{
      color: darken($primary, 15%);
      &:before{
        background-color: darken($primary, 15%);
        color: #fff;
      }
    }
    &:focus{
      outline: 0 none;
    }
    &.bad-response{
      color: #c7254e;
      pointer-events: none;
      &:before{
        content: '\f00d';
        font: normal normal normal 14px/1 FontAwesome;
        border-color: #c7254e;
        background-color: #c7254e;
        color: #FFF;
        font-size: 18px;
        line-height: 31px;
      }
    }
    &.good-response{
      color: #9DDA57;
      pointer-events: none;
      &:before{
        content: "\f00c";
        font: normal normal normal 14px/1 FontAwesome;
        border-color: #9DDA57;
        background-color: #9DDA57;
        color: #FFF;
        font-size: 18px;
        line-height: 31px;
      }
    }
    &:before{
      content: counter(ol-counter, upper-alpha);
      counter-increment: ol-counter;
      position: absolute;
      left: 0;
      border-radius: 100%;
      color: $primary;
      width: 32px;
      height: 32px;
      border:1px solid $primary;
      text-align:center;
      font-weight: bold;
      line-height: 31px;
      top: 5px;
      transition: all 350ms ease;
    }
  }
}

.error-message{
  font-size: 12px;
  font-weight: bold;
  color: #c7254e;
  top: 0;
  margin-bottom: 20px !important;
}

.spinner-holder{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #FFF;
  height: 100vh;
}

.spinner{
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 10px;
  position: relative;
  top: calc(50% - 25px);
  margin: 0 auto;
  text-indent: -9999em;
  border-top: 3px solid rgba(0, 0, 0, 0.2);
  border-right: 3px solid rgba(0, 0, 0, 0.2);
  border-bottom: 3px solid rgba(0, 0, 0, 0.2);
  border-left: 3px solid rgba(0, 0, 0, 0.2);;
  transform: translateZ(0);
  animation: spinner 1.1s infinite linear;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.hidden{
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease;
  pointer-events: none;
  &.active{
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.hidden-box{
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translate3d(0,100%,0);
  transition: all 300ms ease;
  max-height: 0;
  overflow: hidden;
  &.active{
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translate3d(0,0,0);
    max-height: 500px;
  }
}

.concurrency-selector-holder{
  position: absolute;
  bottom:calc(100% + 1px);
  left:0;
  width: 100%;
  background-color: #FFF;
  z-index: 9;
  border-top:1px solid $border-color;
  padding: 10px;
  margin-bottom: 0;
  transition: all 300ms ease;
  transform: translate3d(-100%, 0, 0);
  opacity: 0;
  &.active{
    transform: translate3d(0,0,0);
    opacity: 1;
  }
  i{
    position: absolute;
    right: 10px;
    top: 15px;
  }
}

.concurrency-selector-text{
  display: block;
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 5px;
}

.concurrency-selector{
  display: block;
  margin-bottom: 0;
  font-size: 13px;
  border: 1px solid $border-color;
}

.action-button-holder{
  display: inline-block;
  position: relative;
  width: 40px;
  text-align: center;
  &:hover{
    .tooltip{
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
    .btn-light{
      &.active{
        + .tooltip{
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        }
      }
    }
  }
}

.tooltip{
  position: absolute;
  background-color: #69ca25;
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  bottom: calc(100% + 5px);
  white-space: nowrap;
  right: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 300ms ease;
  &:after{
    content: '';
    @include triangle('down', 10px, 5px, $primary);
    position: absolute;
    top: 100%;
    right: 10px;
  }
}

.main-header{
  padding: 10px;
}

.main-header-col{
  display: inline-block;
  vertical-align: middle;
  &.left{
    width: 50px;
    margin-right: 10px;
  }
  &.right{
    width: calc(100% - 65px)
  }
}

.assistant-title{
  margin-bottom: 0;
}

.assistant-subtitle{
  font-size: 13px;
}

/*------------------------------------------------------------------------------Calendario */
.calendar-holder{
  display: inline-block;
  width: 50%;
  margin-bottom: 20px;
  padding: 20px;
  max-width: 350px;
  border: 1px solid #e7e7e7;
  margin-right: 10px;
  @include respond-to('small'){
    width: 100%;
  }
}

.calendar-title{
  font-weight: bold;
  text-align: center;
  display: block;
  margin-bottom: 5px;
}

/*------------------------------------------------------------------------------Screen */
.screen{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  &.active{
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.inner-form{
  position: absolute;
  top: 50px;
  left: 20px;
  right: 20px;
  max-width: 420px;
  padding: 20px;
  border:1px solid #DDD;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.2);
  margin: 0 auto
}