$font-family-base: 'Open sans', sans-serif;//Familia tipográfica base
$line-height-base: 130%;//Line height base
$font-size-base: 16;//Tamaño base en pixeles
$padding-base: #{(10 / $font-size-base)}rem;

$link-color: #69ca25;//Color del link principal
$link-hover-color: darken($link-color, 15%);
$body-bg: #fff;//Fondo del body
$border-color: #e7e7e7; //Color de los bordes
$box-bg: #f8f8f8; //Fondo de elementos

$h1-size: #{(18 / $font-size-base)}rem;
$h2-size: #{(14 / $font-size-base)}rem;
$h3-size: #{(13 / $font-size-base)}rem;
$h4-size: #{(12 / $font-size-base)}rem;
$h5-size: #{(10 / $font-size-base)}rem;
$h6-size: #{(9 / $font-size-base)}rem;

$margin: 30px; //Margen general

/*----Colores----*/
$main-header-bg: #FFF;

$titles: #333333;
$text-color: #6b6a6f;

$primary: #69ca25;

$bubble-bg: #e7e7e7;
$bubble-bg-message: #33aad1;
$bubble-bg-message-text: #6b6a6f;
$bubble-bg-message-text-send: #FFFFFF;


/*----Breakpoints----*/
$breakpoints: (
  xlarge: "(min-width : 1300px)",
  large: "(min-width: 1024px) and (max-width : 1300px)",
  medium: "(min-width: 768px) and (max-width:1024px)",
  small: "(max-width : 767px)"
);