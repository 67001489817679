@font-face {
  font-family: 'Open sans';
  src: url('fonts/opensans-regular-webfont.woff2') format('woff2'),
        url('fonts/opensans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('fonts/opensans-semibold-webfont.woff2') format('woff2'),
        url('fonts/opensans-semibold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('fonts/opensans-light-webfont.woff2') format('woff2'),
        url('fonts/opensans-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}