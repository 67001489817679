@charset "UTF-8";
/*------------------------------------------------------------------------------Variables */
/*----Colores----*/
/*----Breakpoints----*/
/*------------------------------------------------------------------------------Animaciones */
.animated {
  animation-duration: 500ms;
  animation-fill-mode: both;
  visibility: hidden !important; }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    transform: none; } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInUp {
  animation-name: fadeInUp;
  visibility: visible !important; }

@keyframes wave {
  0%, 60%, 100% {
    transform: initial; }
  30% {
    transform: translateY(-15px); } }

/*------------------------------------------------------------------------------Normalizador */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

button, html, input, select, textarea {
  font-family: sans-serif; }

body {
  margin: 0; }

a:focus {
  outline: thin dotted; }

a:active, a:hover {
  outline: 0; }

h1 {
  font-size: 2em; }

h2 {
  font-size: 1.5em; }

h3 {
  font-size: 1.17em; }

h4 {
  font-size: 1em; }

h5 {
  font-size: .83em; }

h6 {
  font-size: .67em; }

b, strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

mark {
  background: #ff0;
  color: #000; }

p, pre {
  margin: 1em 0; }

code, kbd, pre, samp {
  font-family: monospace,serif;
  _font-family: 'courier new',monospace;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: none; }

q:after, q:before {
  content: '';
  content: none; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

dl, menu, ol, ul {
  margin: 0; }

dd {
  margin: 0 0 0 40px; }

menu, ol, ul {
  padding: 0; }

nav ol, nav ul {
  list-style: none;
  list-style-image: none; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

figure, form {
  margin: 0; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button, input {
  line-height: normal; }

button, select {
  text-transform: none; }

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible; }

button[disabled], html input[disabled] {
  cursor: not-allowed; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
  *height: 13px;
  *width: 13px; }

input[type=search] {
  -webkit-appearance: textfield; }

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button, html, input, select, textarea {
  color: #222; }

body {
  font-size: 1em;
  line-height: 1.5; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: .2em 0;
  background: #ccc;
  color: #000;
  padding: .2em 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em; }

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace; }

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  white-space: nowrap;
  border-radius: 4px; }

kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #ffffff;
  background-color: #333333;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25); }

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.428571429;
  word-break: break-all;
  word-wrap: break-word;
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  border-radius: 4px; }

pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0; }

/*** print */
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a, a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: ""; }
  blockquote, pre {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  img, tr {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: .5cm; }
  h2, h3, p {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; } }

/*------------------------------------------------------------------------------Mixins */
/*------------------------------------------------------------------------------Tipografía */
@font-face {
  font-family: 'Open sans';
  src: url("fonts/opensans-regular-webfont.woff2") format("woff2"), url("fonts/opensans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("fonts/opensans-semibold-webfont.woff2") format("woff2"), url("fonts/opensans-semibold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("fonts/opensans-light-webfont.woff2") format("woff2"), url("fonts/opensans-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

/*------------------------------------------------------------------------------Grillas */
.row {
  width: 100%;
  margin-left: auto;
  margin-right: auto; }

.row [class^="grid"] {
  display: block;
  float: left;
  padding-left: 10px;
  padding-right: 10px; }

.hide {
  display: none !important; }

.grid-1 {
  width: 8.3333333333%; }

.grid-offset-1 {
  margin-left: 8.3333333333%; }

.grid-pull-1 {
  position: relative;
  right: -91.6666666667%; }

.grid-push-1 {
  position: relative;
  left: -91.6666666667%; }

.grid-2 {
  width: 16.6666666667%; }

.grid-offset-2 {
  margin-left: 16.6666666667%; }

.grid-pull-2 {
  position: relative;
  right: -83.3333333333%; }

.grid-push-2 {
  position: relative;
  left: -83.3333333333%; }

.grid-3 {
  width: 25%; }

.grid-offset-3 {
  margin-left: 25%; }

.grid-pull-3 {
  position: relative;
  right: -75%; }

.grid-push-3 {
  position: relative;
  left: -75%; }

.grid-4 {
  width: 33.3333333333%; }

.grid-offset-4 {
  margin-left: 33.3333333333%; }

.grid-pull-4 {
  position: relative;
  right: -66.6666666667%; }

.grid-push-4 {
  position: relative;
  left: -66.6666666667%; }

.grid-5 {
  width: 41.6666666667%; }

.grid-offset-5 {
  margin-left: 41.6666666667%; }

.grid-pull-5 {
  position: relative;
  right: -58.3333333333%; }

.grid-push-5 {
  position: relative;
  left: -58.3333333333%; }

.grid-6 {
  width: 50%; }

.grid-offset-6 {
  margin-left: 50%; }

.grid-pull-6 {
  position: relative;
  right: -50%; }

.grid-push-6 {
  position: relative;
  left: -50%; }

.grid-7 {
  width: 58.3333333333%; }

.grid-offset-7 {
  margin-left: 58.3333333333%; }

.grid-pull-7 {
  position: relative;
  right: -41.6666666667%; }

.grid-push-7 {
  position: relative;
  left: -41.6666666667%; }

.grid-8 {
  width: 66.6666666667%; }

.grid-offset-8 {
  margin-left: 66.6666666667%; }

.grid-pull-8 {
  position: relative;
  right: -33.3333333333%; }

.grid-push-8 {
  position: relative;
  left: -33.3333333333%; }

.grid-9 {
  width: 75%; }

.grid-offset-9 {
  margin-left: 75%; }

.grid-pull-9 {
  position: relative;
  right: -25%; }

.grid-push-9 {
  position: relative;
  left: -25%; }

.grid-10 {
  width: 83.3333333333%; }

.grid-offset-10 {
  margin-left: 83.3333333333%; }

.grid-pull-10 {
  position: relative;
  right: -16.6666666667%; }

.grid-push-10 {
  position: relative;
  left: -16.6666666667%; }

.grid-11 {
  width: 91.6666666667%; }

.grid-offset-11 {
  margin-left: 91.6666666667%; }

.grid-pull-11 {
  position: relative;
  right: -8.3333333333%; }

.grid-push-11 {
  position: relative;
  left: -8.3333333333%; }

.grid-12 {
  width: 100%; }

.grid-offset-12 {
  margin-left: 100%; }

.grid-pull-12 {
  position: relative;
  right: 0%; }

.grid-push-12 {
  position: relative;
  left: 0%; }

@media only screen and (min-width: 1300px) {
  .xlarge-1 {
    width: 8.3333333333%; }
  .xlarge-offset-1 {
    margin-left: 8.3333333333%; }
  .xlarge-pull-1 {
    position: relative;
    right: -91.6666666667%; }
  .xlarge-push-1 {
    position: relative;
    left: -91.6666666667%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-1 {
    width: 8.3333333333%; }
  .large-offset-1 {
    margin-left: 8.3333333333%; }
  .large-pull-1 {
    position: relative;
    right: -91.6666666667%; }
  .large-push-1 {
    position: relative;
    left: -91.6666666667%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-1 {
    width: 8.3333333333%; }
  .medium-offset-1 {
    margin-left: 8.3333333333%; }
  .medium-pull-1 {
    position: relative;
    right: -91.6666666667%; }
  .medium-push-1 {
    position: relative;
    left: -91.6666666667%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .small-1 {
    width: 8.3333333333%; }
  .small-offset-1 {
    margin-left: 8.3333333333%; }
  .small-pull-1 {
    position: relative;
    right: -91.6666666667%; }
  .small-push-1 {
    position: relative;
    left: -91.6666666667%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%; } }

@media only screen and (min-width: 1300px) {
  .xlarge-2 {
    width: 16.6666666667%; }
  .xlarge-offset-2 {
    margin-left: 16.6666666667%; }
  .xlarge-pull-2 {
    position: relative;
    right: -83.3333333333%; }
  .xlarge-push-2 {
    position: relative;
    left: -83.3333333333%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-2 {
    width: 16.6666666667%; }
  .large-offset-2 {
    margin-left: 16.6666666667%; }
  .large-pull-2 {
    position: relative;
    right: -83.3333333333%; }
  .large-push-2 {
    position: relative;
    left: -83.3333333333%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-2 {
    width: 16.6666666667%; }
  .medium-offset-2 {
    margin-left: 16.6666666667%; }
  .medium-pull-2 {
    position: relative;
    right: -83.3333333333%; }
  .medium-push-2 {
    position: relative;
    left: -83.3333333333%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .small-2 {
    width: 16.6666666667%; }
  .small-offset-2 {
    margin-left: 16.6666666667%; }
  .small-pull-2 {
    position: relative;
    right: -83.3333333333%; }
  .small-push-2 {
    position: relative;
    left: -83.3333333333%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%; } }

@media only screen and (min-width: 1300px) {
  .xlarge-3 {
    width: 25%; }
  .xlarge-offset-3 {
    margin-left: 25%; }
  .xlarge-pull-3 {
    position: relative;
    right: -75%; }
  .xlarge-push-3 {
    position: relative;
    left: -75%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-3 {
    width: 25%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-pull-3 {
    position: relative;
    right: -75%; }
  .large-push-3 {
    position: relative;
    left: -75%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-3 {
    width: 25%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-pull-3 {
    position: relative;
    right: -75%; }
  .medium-push-3 {
    position: relative;
    left: -75%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .small-3 {
    width: 25%; }
  .small-offset-3 {
    margin-left: 25%; }
  .small-pull-3 {
    position: relative;
    right: -75%; }
  .small-push-3 {
    position: relative;
    left: -75%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%; } }

@media only screen and (min-width: 1300px) {
  .xlarge-4 {
    width: 33.3333333333%; }
  .xlarge-offset-4 {
    margin-left: 33.3333333333%; }
  .xlarge-pull-4 {
    position: relative;
    right: -66.6666666667%; }
  .xlarge-push-4 {
    position: relative;
    left: -66.6666666667%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-4 {
    width: 33.3333333333%; }
  .large-offset-4 {
    margin-left: 33.3333333333%; }
  .large-pull-4 {
    position: relative;
    right: -66.6666666667%; }
  .large-push-4 {
    position: relative;
    left: -66.6666666667%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-4 {
    width: 33.3333333333%; }
  .medium-offset-4 {
    margin-left: 33.3333333333%; }
  .medium-pull-4 {
    position: relative;
    right: -66.6666666667%; }
  .medium-push-4 {
    position: relative;
    left: -66.6666666667%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .small-4 {
    width: 33.3333333333%; }
  .small-offset-4 {
    margin-left: 33.3333333333%; }
  .small-pull-4 {
    position: relative;
    right: -66.6666666667%; }
  .small-push-4 {
    position: relative;
    left: -66.6666666667%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%; } }

@media only screen and (min-width: 1300px) {
  .xlarge-5 {
    width: 41.6666666667%; }
  .xlarge-offset-5 {
    margin-left: 41.6666666667%; }
  .xlarge-pull-5 {
    position: relative;
    right: -58.3333333333%; }
  .xlarge-push-5 {
    position: relative;
    left: -58.3333333333%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-5 {
    width: 41.6666666667%; }
  .large-offset-5 {
    margin-left: 41.6666666667%; }
  .large-pull-5 {
    position: relative;
    right: -58.3333333333%; }
  .large-push-5 {
    position: relative;
    left: -58.3333333333%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-5 {
    width: 41.6666666667%; }
  .medium-offset-5 {
    margin-left: 41.6666666667%; }
  .medium-pull-5 {
    position: relative;
    right: -58.3333333333%; }
  .medium-push-5 {
    position: relative;
    left: -58.3333333333%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .small-5 {
    width: 41.6666666667%; }
  .small-offset-5 {
    margin-left: 41.6666666667%; }
  .small-pull-5 {
    position: relative;
    right: -58.3333333333%; }
  .small-push-5 {
    position: relative;
    left: -58.3333333333%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%; } }

@media only screen and (min-width: 1300px) {
  .xlarge-6 {
    width: 50%; }
  .xlarge-offset-6 {
    margin-left: 50%; }
  .xlarge-pull-6 {
    position: relative;
    right: -50%; }
  .xlarge-push-6 {
    position: relative;
    left: -50%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-6 {
    width: 50%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-pull-6 {
    position: relative;
    right: -50%; }
  .large-push-6 {
    position: relative;
    left: -50%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-6 {
    width: 50%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-pull-6 {
    position: relative;
    right: -50%; }
  .medium-push-6 {
    position: relative;
    left: -50%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .small-6 {
    width: 50%; }
  .small-offset-6 {
    margin-left: 50%; }
  .small-pull-6 {
    position: relative;
    right: -50%; }
  .small-push-6 {
    position: relative;
    left: -50%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%; } }

@media only screen and (min-width: 1300px) {
  .xlarge-7 {
    width: 58.3333333333%; }
  .xlarge-offset-7 {
    margin-left: 58.3333333333%; }
  .xlarge-pull-7 {
    position: relative;
    right: -41.6666666667%; }
  .xlarge-push-7 {
    position: relative;
    left: -41.6666666667%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-7 {
    width: 58.3333333333%; }
  .large-offset-7 {
    margin-left: 58.3333333333%; }
  .large-pull-7 {
    position: relative;
    right: -41.6666666667%; }
  .large-push-7 {
    position: relative;
    left: -41.6666666667%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-7 {
    width: 58.3333333333%; }
  .medium-offset-7 {
    margin-left: 58.3333333333%; }
  .medium-pull-7 {
    position: relative;
    right: -41.6666666667%; }
  .medium-push-7 {
    position: relative;
    left: -41.6666666667%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .small-7 {
    width: 58.3333333333%; }
  .small-offset-7 {
    margin-left: 58.3333333333%; }
  .small-pull-7 {
    position: relative;
    right: -41.6666666667%; }
  .small-push-7 {
    position: relative;
    left: -41.6666666667%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%; } }

@media only screen and (min-width: 1300px) {
  .xlarge-8 {
    width: 66.6666666667%; }
  .xlarge-offset-8 {
    margin-left: 66.6666666667%; }
  .xlarge-pull-8 {
    position: relative;
    right: -33.3333333333%; }
  .xlarge-push-8 {
    position: relative;
    left: -33.3333333333%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-8 {
    width: 66.6666666667%; }
  .large-offset-8 {
    margin-left: 66.6666666667%; }
  .large-pull-8 {
    position: relative;
    right: -33.3333333333%; }
  .large-push-8 {
    position: relative;
    left: -33.3333333333%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-8 {
    width: 66.6666666667%; }
  .medium-offset-8 {
    margin-left: 66.6666666667%; }
  .medium-pull-8 {
    position: relative;
    right: -33.3333333333%; }
  .medium-push-8 {
    position: relative;
    left: -33.3333333333%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .small-8 {
    width: 66.6666666667%; }
  .small-offset-8 {
    margin-left: 66.6666666667%; }
  .small-pull-8 {
    position: relative;
    right: -33.3333333333%; }
  .small-push-8 {
    position: relative;
    left: -33.3333333333%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%; } }

@media only screen and (min-width: 1300px) {
  .xlarge-9 {
    width: 75%; }
  .xlarge-offset-9 {
    margin-left: 75%; }
  .xlarge-pull-9 {
    position: relative;
    right: -25%; }
  .xlarge-push-9 {
    position: relative;
    left: -25%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-9 {
    width: 75%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-pull-9 {
    position: relative;
    right: -25%; }
  .large-push-9 {
    position: relative;
    left: -25%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-9 {
    width: 75%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-pull-9 {
    position: relative;
    right: -25%; }
  .medium-push-9 {
    position: relative;
    left: -25%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .small-9 {
    width: 75%; }
  .small-offset-9 {
    margin-left: 75%; }
  .small-pull-9 {
    position: relative;
    right: -25%; }
  .small-push-9 {
    position: relative;
    left: -25%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%; } }

@media only screen and (min-width: 1300px) {
  .xlarge-10 {
    width: 83.3333333333%; }
  .xlarge-offset-10 {
    margin-left: 83.3333333333%; }
  .xlarge-pull-10 {
    position: relative;
    right: -16.6666666667%; }
  .xlarge-push-10 {
    position: relative;
    left: -16.6666666667%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-10 {
    width: 83.3333333333%; }
  .large-offset-10 {
    margin-left: 83.3333333333%; }
  .large-pull-10 {
    position: relative;
    right: -16.6666666667%; }
  .large-push-10 {
    position: relative;
    left: -16.6666666667%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-10 {
    width: 83.3333333333%; }
  .medium-offset-10 {
    margin-left: 83.3333333333%; }
  .medium-pull-10 {
    position: relative;
    right: -16.6666666667%; }
  .medium-push-10 {
    position: relative;
    left: -16.6666666667%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .small-10 {
    width: 83.3333333333%; }
  .small-offset-10 {
    margin-left: 83.3333333333%; }
  .small-pull-10 {
    position: relative;
    right: -16.6666666667%; }
  .small-push-10 {
    position: relative;
    left: -16.6666666667%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%; } }

@media only screen and (min-width: 1300px) {
  .xlarge-11 {
    width: 91.6666666667%; }
  .xlarge-offset-11 {
    margin-left: 91.6666666667%; }
  .xlarge-pull-11 {
    position: relative;
    right: -8.3333333333%; }
  .xlarge-push-11 {
    position: relative;
    left: -8.3333333333%; }
  .hide-on-xlarge {
    display: none !important; }
  .only-on-xlarge {
    display: block !important; }
  .down-on-xlarge {
    width: 100%; } }

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .large-11 {
    width: 91.6666666667%; }
  .large-offset-11 {
    margin-left: 91.6666666667%; }
  .large-pull-11 {
    position: relative;
    right: -8.3333333333%; }
  .large-push-11 {
    position: relative;
    left: -8.3333333333%; }
  .hide-on-large {
    display: none !important; }
  .only-on-large {
    display: block !important; }
  .down-on-large {
    width: 100%; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .medium-11 {
    width: 91.6666666667%; }
  .medium-offset-11 {
    margin-left: 91.6666666667%; }
  .medium-pull-11 {
    position: relative;
    right: -8.3333333333%; }
  .medium-push-11 {
    position: relative;
    left: -8.3333333333%; }
  .hide-on-medium {
    display: none !important; }
  .only-on-medium {
    display: block !important; }
  .down-on-medium {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .small-11 {
    width: 91.6666666667%; }
  .small-offset-11 {
    margin-left: 91.6666666667%; }
  .small-pull-11 {
    position: relative;
    right: -8.3333333333%; }
  .small-push-11 {
    position: relative;
    left: -8.3333333333%; }
  .hide-on-small {
    display: none !important; }
  .only-on-small {
    display: block !important; }
  .down-on-small {
    width: 100%; } }

/*------------------------------------------------------------------------------Auxiliares */
.is-loading-page {
  opacity: 0;
  visibility: none;
  transition: opacity 300ms ease; }
  .is-loading-page.active {
    opacity: 1;
    visibility: visible; }

.to-the-left {
  float: left; }

.to-the-right {
  float: right; }

.align-left {
  text-align: left; }

.align-center {
  text-align: center; }

.align-right {
  text-align: right; }

.relative-position {
  position: relative; }

.inline-list {
  display: table;
  width: 100%; }
  .inline-list li {
    display: table-cell;
    vertical-align: middle; }
  .inline-list.floated {
    display: block; }
    .inline-list.floated li {
      display: block;
      float: left; }

.vertical-list {
  display: block; }
  .vertical-list li {
    display: block; }
    .vertical-list li a {
      display: block;
      padding: 10px; }

.delay-animation.delay-0 {
  animation-delay: 0ms; }

.delay-animation.delay-1 {
  animation-delay: 450ms; }

.delay-animation.delay-2 {
  animation-delay: 900ms; }

.delay-animation.delay-3 {
  animation-delay: 1350ms; }

.delay-animation.delay-4 {
  animation-delay: 1800ms; }

.delay-animation.delay-5 {
  animation-delay: 2250ms; }

.delay-animation.delay-6 {
  animation-delay: 2700ms; }

.delay-animation.delay-7 {
  animation-delay: 3150ms; }

.delay-animation.delay-8 {
  animation-delay: 3600ms; }

.delay-animation.delay-9 {
  animation-delay: 4050ms; }

.delay-animation.delay-10 {
  animation-delay: 4500ms; }

.delay-animation.delay-11 {
  animation-delay: 4950ms; }

.m-r-10 {
  margin-right: 10px; }

.m-r-20 {
  margin-right: 20px; }

.m-r-30 {
  margin-right: 30px; }

.m-r-40 {
  margin-right: 40px; }

.m-r-50 {
  margin-right: 50px; }

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc; }

.button-reset, .btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: transparent;
  border-radius: 0px;
  border: 0px;
  outline: 0px;
  padding: 0px; }

.elastic-img {
  width: 100%;
  height: auto; }

.elastic-img-limit {
  max-width: 100%;
  height: auto; }

.centered-img {
  display: block;
  margin: 0 auto; }

.clearfix, .inline-list.floated {
  *zoom: 1; }
  .clearfix:before, .inline-list.floated:before, .clearfix:after, .inline-list.floated:after {
    content: " ";
    display: table; }
  .clearfix:after, .inline-list.floated:after {
    clear: both; }

.rounded-img {
  border-radius: 100%;
  border: 1px solid #e7e7e7; }

.clear-margin {
  margin: 0; }

.margin-bottom-wrapp {
  margin-bottom: 20px; }

.container {
  padding-left: 20px;
  padding-right: 20px; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.ohidden {
  overflow: hidden; }

.hide {
  display: none; }

.box-wrapp {
  padding: 0.625rem; }

.bordered {
  border: 1px solid #e7e7e7; }
  .bordered.top {
    border-bottom: 0 none;
    border-left: 0 none;
    border-right: 0 none; }

.disabled {
  opacity: 0.3;
  pointer-events: none; }

.disable-interaction {
  pointer-events: none; }

.inline-left {
  margin-right: 10px; }

.inline-element {
  display: inline-block; }

.block-element {
  display: block; }

.wrapp-30-0 {
  padding: 30px 0; }

.bordered-bottom {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e7e7e7; }

/*------------------------------------------------------------------------------Generales */
html {
  font-size: 16px; }

body {
  font-family: "Open sans", sans-serif;
  line-height: 130%;
  background-color: #fff;
  font-size: 16px;
  -webkit-backface-visibility: hidden;
  color: #6b6a6f; }
  body.preload {
    overflow: hidden; }

button {
  font-family: "Open sans", sans-serif; }

a {
  color: #69ca25;
  font-weight: bold;
  text-decoration: none;
  outline: 0 none;
  transition: all 350ms ease; }
  a:hover {
    color: #478919;
    outline: 0 none; }
  a:focus {
    outline: 0 none; }

ul {
  margin-left: 0; }

p {
  font-size: 1rem;
  margin: 0;
  line-height: 130%;
  margin-bottom: 30px; }
  p:last-child {
    margin-bottom: 0; }

/*------------------------------------------------------------------------------Fondos */
/*------------------------------------------------------------------------------Headlines */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  color: #333333;
  line-height: 120%;
  margin-bottom: 10px; }

h1 {
  font-size: 1.125rem; }

h2 {
  font-size: 0.875rem; }

h3 {
  font-size: 0.8125rem; }

h4 {
  font-size: 0.75rem; }

h5 {
  font-size: 0.625rem; }

h6 {
  font-size: 0.5625rem; }

.rate-title {
  font-size: 1.125rem; }

/*------------------------------------------------------------------------------Buttons */
.btn {
  font-family: "Open sans", sans-serif;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  padding: 10px;
  width: auto;
  height: auto;
  line-height: 120%;
  transition: background 300ms ease;
  color: #FFFFFF;
  transition: all 350ms ease;
  background-color: #69ca25;
  border-radius: 10px; }
  .btn:hover {
    background-color: #75d930;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3); }
  .btn.btn-rounded {
    border-radius: 100%; }
  .btn.btn-close {
    width: 32px;
    height: 32px;
    padding: 0;
    position: absolute;
    top: 10px;
    right: 10px; }
  .btn.btn-big {
    font-size: 1rem;
    padding: 10px 20px;
    margin: 5px; }
  .btn.btn-width-150 {
    width: 150px; }
  .btn.btn-transparent {
    background-color: transparent;
    border: 0 none; }
  .btn.btn-alternative {
    background-color: transparent;
    border: 0 none;
    color: #69ca25;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    text-align: left; }
    .btn.btn-alternative:hover {
      box-shadow: none; }
    .btn.btn-alternative:focus:before {
      background-color: #CCC; }
    .btn.btn-alternative:before {
      content: "";
      width: 32px;
      height: 32px;
      border: 1px solid #69ca25;
      border-radius: 100%;
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle; }
    .btn.btn-alternative.bad-response {
      background-color: transparent;
      color: #c7254e; }
      .btn.btn-alternative.bad-response:before {
        background-color: #c7254e;
        border: 1px solid #c7254e;
        text-align: center;
        line-height: 180%; }
    .btn.btn-alternative.good-response {
      background-color: transparent;
      color: #9DDA57; }
      .btn.btn-alternative.good-response:before {
        background-color: #9DDA57;
        border: 1px solid #9DDA57;
        text-align: center;
        line-height: 180%; }
  .btn.bad-response {
    background-color: #c7254e; }
    .btn.bad-response:before {
      content: '\f00d';
      font: normal normal normal 14px/1 FontAwesome;
      color: #FFF;
      margin-right: 5px;
      font-size: 18px; }
  .btn.good-response {
    background-color: #9DDA57; }
    .btn.good-response:before {
      content: '\f00c';
      font: normal normal normal 14px/1 FontAwesome;
      color: #FFF;
      margin-right: 5px;
      font-size: 18px; }
  .btn.btn-light {
    background-color: transparent;
    color: #69ca25;
    border: 1px solid #69ca25;
    border-radius: 100%;
    height: 32px;
    width: 32px;
    padding: 0;
    box-shadow: none; }
    .btn.btn-light.active {
      background-color: #69ca25;
      color: #FFF; }
  .btn.btn-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-shadow: -3px 0px 6px rgba(0, 0, 0, 0.3);
    background-color: #35abd1;
    border-radius: 0;
    border: 0 none;
    color: #FFF;
    padding: 10px;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
    pointer-events: none;
    z-index: 2; }
    .btn.btn-header.active {
      opacity: 1;
      visibility: visible;
      transform: translate3d(0, 0, 0);
      pointer-events: auto; }
    .btn.btn-header:hover {
      background-color: #227b97; }

.stars-holder {
  overflow: hidden;
  display: inline-block; }

.star-btn {
  border: 0 none;
  background-color: transparent;
  font-size: 1.875rem;
  float: right; }
  .star-btn i {
    color: #e7e7e7; }
  .star-btn:hover i, .star-btn.active i {
    color: #ffa900; }
  .star-btn:hover ~ .star-btn i, .star-btn.active ~ .star-btn i {
    color: #ffa900; }
  .star-btn:focus {
    outline: 0 none; }

/*------------------------------------------------------------------------------Forms */
label {
  display: block;
  margin-bottom: 10px;
  text-align: left; }

input, select, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  background-color: transparent;
  border: 0 none;
  padding: 10px;
  transition: all 350ms ease;
  height: 45px;
  background-color: #FFF;
  font-size: 1rem;
  border: 1px solid #DDD; }
  input:hover, select:hover, textarea:hover {
    border-color: #333333; }
  input:focus, select:focus, textarea:focus {
    outline: 0 none; }

input[type="radio"],
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid #e7e7e7;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle; }
  input[type="radio"]:checked,
  input[type="checkbox"]:checked {
    background-color: #69ca25; }
  input[type="radio"] + span,
  input[type="checkbox"] + span {
    font-weight: bold;
    margin-right: 20px;
    display: inline-block; }
    input[type="radio"] + span.label-like,
    input[type="checkbox"] + span.label-like {
      margin-right: 0;
      width: calc(100% - 32px);
      vertical-align: top;
      font-weight: normal; }

input[type="checkbox"] {
  border-radius: 0; }

.small-input {
  height: 30px;
  padding: 5px 30px 5px 5px; }

.invalid-input {
  border: 1px solid #c74325 !important; }

textarea {
  min-height: 350px; }

select {
  padding-right: 30px;
  position: relative;
  border-radius: 0; }
  select:after {
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 0; }

@media only screen and (max-width: 767px) {
  .campaign-select-holder {
    float: none;
    padding: 10px 0;
    display: block; } }

.select-holder {
  position: relative;
  display: inline-block; }
  .select-holder:after {
    content: "\f0dc";
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 20px;
    color: #69ca25;
    text-align: center;
    line-height: 320%; }
  .select-holder select {
    margin-bottom: 0; }

/*------------------------------------------------------------------------------Header */
.main-header {
  padding: 10px;
  border-bottom: 1px solid #e7e7e7;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: #FFF; }

.logo-img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px; }

.main-title {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  width: calc(100% - 70px); }

/*------------------------------------------------------------------------------Content */
.input-user-holder {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e7e7e7; }

.form-wrapp {
  transition: all 300ms ease;
  border-top: 1px solid #999; }
  .form-wrapp.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, 100%, 0); }

.input-user {
  width: calc(100% - 45px);
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  height: 50px;
  background-color: #d6d6d6; }

.input-user-btn {
  display: inline-block;
  vertical-align: middle; }

.conversation-holder {
  padding-top: 80px;
  height: calc(100vh - 61px);
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 50px;
  position: relative; }

.conversation-bubble {
  margin-bottom: 15px; }
  .conversation-bubble.to {
    text-align: right; }

.response-img {
  display: inline-block;
  vertical-align: top;
  width: 50px;
  margin-right: 10px; }

.bubble {
  display: inline-block;
  background-color: #e7e7e7;
  padding: 15px;
  border-radius: 30px;
  margin-top: 3px;
  max-width: calc(100% - 70px);
  color: #6b6a6f;
  font-size: 0.875rem; }
  .bubble a {
    color: #69ca25;
    text-decoration: underline; }
    .bubble a:hover {
      text-decoration: none; }
  .to .bubble {
    background-color: #33aad1;
    color: #FFFFFF;
    text-align: left; }

.loader {
  text-align: center;
  width: 100px;
  position: absolute;
  bottom: 100%;
  height: 30px;
  margin: 0 auto;
  left: 0;
  right: 0; }
  .loader .dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 3px;
    background: #69ca25;
    animation: wave 1.3s linear infinite; }
    .loader .dot:nth-child(2) {
      animation-delay: -1.1s; }
    .loader .dot:nth-child(3) {
      animation-delay: -0.9s; }

.conversation-wrapper {
  margin-bottom: 50px; }

.block-textarea {
  border: 1px solid #e7e7e7;
  min-height: 100px; }

/*------------------------------------------------------------------------------Tables */
table {
  width: 100%; }
  table th, table td {
    text-align: left;
    border-bottom: 1px solid #e7e7e7;
    font-size: 0.75rem; }
  table th {
    padding: 5px; }
  table tr:last-child td {
    border-bottom: 0 none; }
  table td {
    padding: 5px;
    position: relative;
    transition: background 300ms ease;
    text-align: center;
    color: #e7e7e7; }
    table td.active {
      font-weight: bold; }
    table td.main-td {
      font-weight: bold;
      font-size: 1.125rem;
      min-width: 200px;
      white-space: nowrap; }
    @media only screen and (max-width: 767px) {
      table td {
        white-space: nowrap; } }

/*------------------------------------------------------------------------------Contenedores */
.question-holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: #FFF;
  padding: 20px;
  text-align: center;
  overflow: hidden;
  overflow-y: scroll; }
  .question-holder .question-holder-wrapp {
    opacity: 0;
    pointer-events: none;
    transition: all 350ms ease; }
  .question-holder.active .question-holder-wrapp {
    opacity: 1;
    pointer-events: auto; }

.question-data {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  pointer-events: none;
  transition: opacity 350ms ease; }
  .question-data.active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    position: static; }

.question-last-step {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 350ms ease;
  pointer-events: none;
  background-color: #FFF;
  padding: 40px; }
  .question-last-step.active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto; }
  .question-last-step i {
    font-size: 2.625rem;
    margin-bottom: 30px; }

.question-holder-wrapp {
  margin: 0 auto;
  max-width: 600px; }

.question-title {
  font-size: 0.875rem;
  margin-bottom: 30px; }

.button-list-holder {
  text-align: left;
  list-style: none;
  counter-reset: ol-counter;
  text-align: left;
  max-width: 350px;
  margin: 0 auto;
  margin-bottom: 20px; }
  .button-list-holder li {
    position: relative;
    padding-left: 45px;
    margin-bottom: 20px; }
  .button-list-holder .button-list {
    color: #69ca25;
    background-color: transparent;
    border: 0 none;
    text-align: left;
    padding: 0;
    padding-top: 12px;
    font-size: 0.75rem; }
    .button-list-holder .button-list:hover {
      color: #478919; }
      .button-list-holder .button-list:hover:before {
        background-color: #478919;
        color: #fff; }
    .button-list-holder .button-list:focus {
      outline: 0 none; }
    .button-list-holder .button-list.bad-response {
      color: #c7254e;
      pointer-events: none; }
      .button-list-holder .button-list.bad-response:before {
        content: '\f00d';
        font: normal normal normal 14px/1 FontAwesome;
        border-color: #c7254e;
        background-color: #c7254e;
        color: #FFF;
        font-size: 18px;
        line-height: 31px; }
    .button-list-holder .button-list.good-response {
      color: #9DDA57;
      pointer-events: none; }
      .button-list-holder .button-list.good-response:before {
        content: "\f00c";
        font: normal normal normal 14px/1 FontAwesome;
        border-color: #9DDA57;
        background-color: #9DDA57;
        color: #FFF;
        font-size: 18px;
        line-height: 31px; }
    .button-list-holder .button-list:before {
      content: counter(ol-counter, upper-alpha);
      counter-increment: ol-counter;
      position: absolute;
      left: 0;
      border-radius: 100%;
      color: #69ca25;
      width: 32px;
      height: 32px;
      border: 1px solid #69ca25;
      text-align: center;
      font-weight: bold;
      line-height: 31px;
      top: 5px;
      transition: all 350ms ease; }

.error-message {
  font-size: 12px;
  font-weight: bold;
  color: #c7254e;
  top: 0;
  margin-bottom: 20px !important; }

.spinner-holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #FFF;
  height: 100vh; }

.spinner {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 10px;
  position: relative;
  top: calc(50% - 25px);
  margin: 0 auto;
  text-indent: -9999em;
  border-top: 3px solid rgba(0, 0, 0, 0.2);
  border-right: 3px solid rgba(0, 0, 0, 0.2);
  border-bottom: 3px solid rgba(0, 0, 0, 0.2);
  border-left: 3px solid rgba(0, 0, 0, 0.2);
  transform: translateZ(0);
  animation: spinner 1.1s infinite linear; }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.hidden {
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease;
  pointer-events: none; }
  .hidden.active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto; }

.hidden-box {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translate3d(0, 100%, 0);
  transition: all 300ms ease;
  max-height: 0;
  overflow: hidden; }
  .hidden-box.active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translate3d(0, 0, 0);
    max-height: 500px; }

.concurrency-selector-holder {
  position: absolute;
  bottom: calc(100% + 1px);
  left: 0;
  width: 100%;
  background-color: #FFF;
  z-index: 9;
  border-top: 1px solid #e7e7e7;
  padding: 10px;
  margin-bottom: 0;
  transition: all 300ms ease;
  transform: translate3d(-100%, 0, 0);
  opacity: 0; }
  .concurrency-selector-holder.active {
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .concurrency-selector-holder i {
    position: absolute;
    right: 10px;
    top: 15px; }

.concurrency-selector-text {
  display: block;
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 5px; }

.concurrency-selector {
  display: block;
  margin-bottom: 0;
  font-size: 13px;
  border: 1px solid #e7e7e7; }

.action-button-holder {
  display: inline-block;
  position: relative;
  width: 40px;
  text-align: center; }
  .action-button-holder:hover .tooltip {
    opacity: 1;
    visibility: visible;
    pointer-events: auto; }
  .action-button-holder:hover .btn-light.active + .tooltip {
    opacity: 0;
    visibility: hidden;
    pointer-events: none; }

.tooltip {
  position: absolute;
  background-color: #69ca25;
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  bottom: calc(100% + 5px);
  white-space: nowrap;
  right: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 300ms ease; }
  .tooltip:after {
    content: '';
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #69ca25;
    position: absolute;
    top: 100%;
    right: 10px; }

.main-header {
  padding: 10px; }

.main-header-col {
  display: inline-block;
  vertical-align: middle; }
  .main-header-col.left {
    width: 50px;
    margin-right: 10px; }
  .main-header-col.right {
    width: calc(100% - 65px); }

.assistant-title {
  margin-bottom: 0; }

.assistant-subtitle {
  font-size: 13px; }

/*------------------------------------------------------------------------------Calendario */
.calendar-holder {
  display: inline-block;
  width: 50%;
  margin-bottom: 20px;
  padding: 20px;
  max-width: 350px;
  border: 1px solid #e7e7e7;
  margin-right: 10px; }
  @media only screen and (max-width: 767px) {
    .calendar-holder {
      width: 100%; } }

.calendar-title {
  font-weight: bold;
  text-align: center;
  display: block;
  margin-bottom: 5px; }

/*------------------------------------------------------------------------------Screen */
.screen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  pointer-events: none; }
  .screen.active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto; }

.inner-form {
  position: absolute;
  top: 50px;
  left: 20px;
  right: 20px;
  max-width: 420px;
  padding: 20px;
  border: 1px solid #DDD;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  margin: 0 auto; }
